/// REACT ///
import { useEffect } from "react";
import { useState } from "react";
/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { getColors } from "api/actions/material";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ColorHeader from "../header/color_header";
import EditableList from "components/universal/editable_list";
import EditMaterial from "../dialog/edit_material";
import ColorItem from "components/material/row/color_item";
/// ICONS ///
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from "@mui/icons-material/Edit";
/// COMPONENTS ///
import CreateColors from "../dialog/create_colors";
/// TYPES ///
import { Material } from "api/types/material";
import { Color } from "api/types/material";
/// VALUES ///
import { default_pagination_length } from "styles/table";
import { getMaterialHeightDisplay } from "values/material";

interface Props {
  start_update: (color: Color) => void,
  material: Material
}

export default function MaterialItem({ start_update, material }: Props) {
  const dispatch = useAppDispatch();
  const [show_archived, setShowArchived] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const { colors } = useAppSelector((state) => state.material);

  const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    if (material?.id) {
      dispatch(getColors(material.id));
    }
  }, [dispatch, material?.id]);

  return (
    <Grid container item alignItems="center">
      <Grid item xs={8} sx={{ paddingBottom: 1 }}>
        <Typography variant="h4">
          {
            material && material.id ?
              `${material.name} (${getMaterialHeightDisplay(material.thickness)})` :
              ""
          }
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end">
          <CreateColors material={material} />
          <Tooltip title="Show Archived">
            <IconButton onClick={() => setShowArchived(prev => !prev)}>
              {
                show_archived ?
                  <VisibilityOffIcon /> :
                  <VisibilityIcon />
              }
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Material">
            <IconButton onClick={() => setOpen(true)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">Total Number: {colors?.filter(item => item.enabled).length}</Typography>
      </Grid>
      <ColorHeader />
      <EditableList
        start_update={start_update}
        items={show_archived ?
          colors.slice((page - 1) * default_pagination_length, page * default_pagination_length) :
          colors.slice((page - 1) * default_pagination_length, page * default_pagination_length)
            .filter(item => item.enabled)
        }
        Item={ColorItem} />
      <Grid item xs={12} sx={{ marginTop: 3 }}>
        <Box display="flex" justifyContent="center">
          <Pagination
            onChange={changePage}
            count={
              colors.length > default_pagination_length ?
                Math.ceil(colors.length / default_pagination_length) :
                1
            } shape="rounded" />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <EditMaterial open={open} setOpen={setOpen} />
      </Grid>
    </Grid>
  )
}
