/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useParams } from "react-router-dom";
/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// ACTIONS ///
import { getUploadInstance } from "api/actions/material_import";
/// COMPONENTS ///
import Title from "components/universal/title";
import CreateUpload from "./dialog/create_upload";
import UploadInfo from "./row/info";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
/// STYLES ///
import { islandStyle } from "styles/style";
import { mainContentAreaStyle } from "styles/style";
/// VALUES ///
import { getMaterialImportStatusDisplay } from "values/import_material";

export default function UploadInstanceComponent() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { upload } = useAppSelector(state => state.material_import);

  useEffect(() => {
    if (id) {
      dispatch(getUploadInstance(id));
    }
  }, [dispatch, id]);

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Title
            title={getMaterialImportStatusDisplay(upload.resolution)}
            subtitle={`By ${upload.username} on ${upload.date}`}
            update_pending={false}
            start_times={[]}
            update={() => void 0}
            endProps={<CreateUpload />} />
        </Grid>
        <Grid item xs={8.5}>
          <Paper sx={islandStyle}>
            {
              upload?.infos?.map(info => <UploadInfo info={info} />)
            }
          </Paper>
        </Grid>
        <Grid item xs={3}>
        </Grid>
      </Grid>
    </Box>
  )
}
