import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { UploadInstance } from 'api/types/material_import';
import { rowItemStyle } from 'styles/style';
import { getRowSecondStyle } from 'values/style';
import { getDate } from 'functions/date';
import { getMaterialImportStatusDisplay } from 'values/import_material';
import { material_import_url } from 'components/navigation/endpoints';

interface Props {
    item: UploadInstance,
    index: number
}

export default function UploadRow({item, index}: Props) {
    const navigate = useNavigate();

    return(
        <Grid container item alignItems="center"
            onClick={() => navigate(`/${material_import_url}/${item.id}`, {replace: false})}
            sx={[getRowSecondStyle(index), rowItemStyle]}>
            <Grid item xs={3}>
                <Typography variant="body2" sx={{marginLeft: 0.1}}>
                    {getDate(item.date)}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2">
                    {item.username}
                </Typography>
            </Grid>
            <Grid item xs={2}>
              {item?.description ?? ""}
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2" align="right">
                    {getMaterialImportStatusDisplay(item.resolution)}
                </Typography>
            </Grid>
        </Grid>
    )
}
