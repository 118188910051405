/// MUI ///
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// TYPES ///
import { UploadInfo } from "api/types/material_import";
/// STYLES ///
import { getRowSecondStyle } from "values/style";
import { rowItemStyle } from "styles/style";

interface Props {
    info: UploadInfo
}

export default function InfoRow({info}: Props) {
    return(
    <Grid container item alignItems="flex-start" sx={{border: "1px solid #666666"}}>
        <Grid item xs={4}>
          <Typography variant="body2">
              {info.line}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Stack>
          {
            info?.messages?.map((message, index) => <Typography  sx={[getRowSecondStyle(index), rowItemStyle]} variant="body2">{message?.message}</Typography> )
          }
          </Stack>
        </Grid>
      </Grid>
    )
}
